.search {
  display: flex;
  position: sticky;
  top: 110px;
  input {
    flex: 1;
    background-color: #343333;
    color: white;
    padding: 12px;
    border: 0;
    border-radius: 2px 0 0 2px;
    &::-webkit-input-placeholder {
      color: var(--c, black); } }
  button {
    padding: 12px;
    border-radius: 0 2px 2px 0;
    border: 0;
    background-color: #3b3b3b;
    color: white;
    &:hover {
      cursor: pointer;
      background-color:  #343333; } } }
